import {
  CREATE_PAGE_REQUEST,
  CREATE_PAGE_SUCCESS,
  CREATE_PAGE_FAILURE,
  GET_PAGES_REQUEST,
  GET_PAGES_SUCCESS,
  GET_PAGES_FAILURE,
  DELETE_PAGE_REQUEST,
  DELETE_PAGE_SUCCESS,
  DELETE_PAGE_FAILURE,
  PAGE_DETAILS_REQUEST,
  PAGE_DETAILS_SUCCESS,
  PAGE_DETAILS_FAIL,
} from "../actions/types";

const initialState = {
  loading: false,
  pages: [],
  page: null,
  error: null,
};

export const createPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PAGE_REQUEST:
      return { ...state, loading: true, error: null };
    case CREATE_PAGE_SUCCESS:
      return { ...state, loading: false, page: action.payload };
    case CREATE_PAGE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAGES_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_PAGES_SUCCESS:
      return { ...state, loading: false, pages: action.payload.pages };
    case GET_PAGES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deletePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_PAGE_REQUEST:
      return { ...state, loading: true, error: null };
    case DELETE_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        pages: state.pages.filter((page) => page._id !== action.payload),
      };
    case DELETE_PAGE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const pageDetailsReducer = (state = { pageDetails: {} }, action) => {
  switch (action.type) {
    case PAGE_DETAILS_REQUEST:
      return { loading: true, ...state };

    case PAGE_DETAILS_SUCCESS:
      return { loading: false, pageDetails: action.payload };

    case PAGE_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
