import { 
  GET_CONTENT_TYPES, 
  GET_CONTENT_TYPES_FAIL,  
  ADD_CONTENT_TYPE_REQUEST,
  ADD_CONTENT_TYPE_SUCCESS,
  ADD_CONTENT_TYPE_FAILURE, 
  DELETE_CONTENT_TYPE_REQUEST,
  DELETE_CONTENT_TYPE_SUCCESS,
  DELETE_CONTENT_TYPE_FAIL,
  UPDATE_CONTENT_TYPE_REQUEST,
  UPDATE_CONTENT_TYPE_SUCCESS,
  UPDATE_CONTENT_TYPE_FAIL,
} from "../actions/types";

const initialState = {
  contentTypes: [],
  loading: false,
  success: false,
  error: null,
  data: [],
};

const contentTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTENT_TYPES:
      return {
        ...state,
        contentTypes: action.payload,
        error: null,
      };

    case GET_CONTENT_TYPES_FAIL:
      return {
        ...state,
        contentTypes: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

export default contentTypeReducer;

export const addContentTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CONTENT_TYPE_REQUEST:
      return { 
        ...state, 
        loading: true 
      };
    case ADD_CONTENT_TYPE_SUCCESS:
      return { 
        loading: false, 
        success: true, 
        error: null 
      };
    case ADD_CONTENT_TYPE_FAILURE:
      return { 
        loading: false, 
        success: false, 
        error: action.payload 
      };
    default:
      return state;
  }
};

export const deleteContentTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_CONTENT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DELETE_CONTENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter(
          (content) => content._id !== action.payload
        ),
      };

    case DELETE_CONTENT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const UpdatecontentTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONTENT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_CONTENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        contentTypes: state.contentTypes.map((type) =>
          type._id === action.payload._id ? action.payload : type
        ),
      };

    case UPDATE_CONTENT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};