export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const CREATE_NEW_PASSWORD = "CREATE_NEW_PASSWORD";
export const ADD_MOVIE = "ADD_MOVIE";
export const ADD_MOVIE_FAIL = "ADD_MOVIE_FAIL";
export const LIST_MOVIE = "LIST_MOVIE";
export const LIST_MOVIE_FAIL = "LIST_MOVIE_FAIL";
export const GET_MOVIE = "GET_MOVIE";
export const GET_RECENT_MOVIE = "GET_RECENT_MOVIE";
export const UPDATE_MOVIE = "UPDATE_MOVIE";
export const DELETE_MOVIE = "DELETE_MOVIE";
export const ADD_USER = "ADD_USER";
export const ADD_USER_FAIL = "ADD_USER_FAIL";
export const LIST_USER = "LIST_USER";
export const LIST_USER_FAIL = "LIST_USER_FAIL";
export const GET_USER = "GET_USER";
export const GET_RECENT_USER = "GET_RECENT_USER";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_FAIL = "DELETE_USER";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const GET_CATEGORY = "GET_CATEGORY";
export const LIST_CATEGORY = "LIST_CATEGORY";
export const LIST_CATEGORY_FAIL = "LIST_CATEGORY_FAIL";
export const ADD_COUPON = "ADD_COUPON";
export const EDIT_COUPON = "EDIT_COUPON";
export const DELETE_COUPON = "DELETE_COUPON";
export const GET_COUPON = "GET_COUPON";
export const LIST_COUPON = "LIST_COUPON";
export const LIST_COUPON_FAIL = "LIST_COUPON_FAIL";
export const ADD_LANGUAGE = "ADD_LANGUAGE";
export const DELETE_LANGUAGE = "DELETE_LANGUAGE";
export const GET_LANGUAGE = "GET_LANGUAGE";
export const LIST_LANGUAGE = "LIST_LANGUAGE";
export const LIST_LANGUAGE_FAIL = "LIST_LANGUAGE_FAIL";
export const ADD_CAST = "ADD_CAST";
export const DELETE_CAST = "DELETE_CAST";
export const GET_CAST = "GET_CAST";
export const LIST_CAST = "LIST_CAST";
export const LIST_CAST_FAIL = "LIST_CAST_FAIL";
export const ADD_REVIEW = "ADD_REVIEW";
export const DELETE_REVIEW = "DELETE_REVIEW";
export const GET_REVIEW = "GET_REVIEW";
export const LIST_REVIEW = "LIST_REVIEW";
export const LIST_REVIEW_FAIL = "LIST_REVIEW_FAIL";
export const UPLOAD_CAST_IMG = "UPLOAD_CAST_IMG";

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const NOTIFICATION_LIST = "NOTIFICATION_LIST";
//
export const LIST_USERS_MOVIE = "LIST_USERS_MOVIE";
export const USERS_MOVIE_FAIL = "USERS_MOVIE_FAIL";
export const GET_USERS_MOVIE = "GET_USERS_MOVIE";
export const DELETE_USER_MOVIE = "DELETE_USER_MOVIE";
//
export const UPLOAD_MOVIE = "UPLOAD_MOVIE";
export const UPLOAD_TRAILER = "UPLOAD_TRAILER";
export const UPLOAD_BANNERS = "UPLOAD_BANNERS";
export const UPLOAD_SUBTITLES = "UPLOAD_SUBTITLES";
//
export const LIST_ORDER = "LIST_ORDER";
export const LIST_ORDER_FAIL = "LIST_ORDER_FAIL";
export const DELETE_ORDER = "DELETE_ORDER";
//
export const VERIFY_PASSWORD = "VERIFY_PASSWORD";
export const SUPPORT_LIST = "SUPPORT_LIST";
// created a type
export const JOINME_LIST = "JOINME_LIST";

// For handle Play section action types.
export const LIST_PLAY = "LIST_PLAY";
export const LIST_PLAY_FAIL = "LIST_MOVIE_FAIL";
// For play delete & update action types.
export const DELETE_PLAY = "DELETE_PLAY";
export const UPDATE_PLAY = "UPDATE_PLAY";

export const GET_PLAY = "GET_PLAY";
export const UPLOAD_PLAY = "UPLOAD_PLAY";
export const UPLOAD_PLAY_TRAILER = "UPLOAD_PLAY_TRAILER";
export const UPLOAD_PLAY_BANNERS = "UPLOAD_PLAY_BANNERS";
export const UPLOAD_PLAY_SUBTITLES = "UPLOAD_PLAY_SUBTITLES";
export const GET_RECENT_PLAY = "GET_RECENT_PLAY";

// Get Content Types
export const GET_CONTENT_TYPES = "GET_CONTENT_TYPES";
export const GET_CONTENT_TYPES_FAIL = "GET_CONTENT_TYPES_FAIL";

// Add content types
export const ADD_CONTENT_TYPE_REQUEST = "ADD_CONTENT_TYPE_REQUEST";
export const ADD_CONTENT_TYPE_SUCCESS = "ADD_CONTENT_TYPE_SUCCESS";
export const ADD_CONTENT_TYPE_FAILURE = "ADD_CONTENT_TYPE_FAILURE";

// Delete content type
export const DELETE_CONTENT_TYPE_REQUEST = "DELETE_CONTENT_TYPE_REQUEST";
export const DELETE_CONTENT_TYPE_SUCCESS = "DELETE_CONTENT_TYPE_SUCCESS";
export const DELETE_CONTENT_TYPE_FAIL = "DELETE_CONTENT_TYPE_FAIL";

// Update Content Type
export const UPDATE_CONTENT_TYPE_REQUEST = "UPDATE_CONTENT_TYPE_REQUEST";
export const UPDATE_CONTENT_TYPE_SUCCESS = "UPDATE_CONTENT_TYPE_SUCCESS";
export const UPDATE_CONTENT_TYPE_FAIL = "UPDATE_CONTENT_TYPE_FAIL";
export const ADD_TAG = "ADD_TAG";
export const DELETE_TAG = "DELETE_TAG";
export const GET_TAG = "GET_TAG";
export const UPDATE_TAG = "UPDATE_TAG";
export const LIST_TAGS = "LIST_TAGS";
export const LIST_TAGS_FAIL = "LIST_TAGS_FAIL";

//Create New Page
export const CREATE_PAGE_REQUEST = "CREATE_PAGE_REQUEST";
export const CREATE_PAGE_SUCCESS = "CREATE_PAGE_SUCCESS";
export const CREATE_PAGE_FAILURE = "CREATE_PAGE_FAILURE";

//Get Pages List
export const GET_PAGES_REQUEST = "GET_PAGES_REQUEST";
export const GET_PAGES_SUCCESS = "GET_PAGES_SUCCESS";
export const GET_PAGES_FAILURE = "GET_PAGES_FAILURE";

//Delete Page
export const DELETE_PAGE_REQUEST = "DELETE_PAGE_REQUEST";
export const DELETE_PAGE_SUCCESS = "DELETE_PAGE_SUCCESS";
export const DELETE_PAGE_FAILURE = "DELETE_PAGE_FAILURE";

//Get Page Details
export const PAGE_DETAILS_REQUEST = "PAGE_DETAILS_REQUEST";
export const PAGE_DETAILS_SUCCESS = "PAGE_DETAILS_SUCCESS";
export const PAGE_DETAILS_FAIL = "PAGE_DETAILS_FAIL";

//Create Data List
export const CREATE_DATALIST_REQUEST = "CREATE_DATALIST_REQUEST";
export const CREATE_DATALIST_SUCCESS = "CREATE_DATALIST_SUCCESS";
export const CREATE_DATALIST_FAILURE = "CREATE_DATALIST_FAILURE";

//Get Data List
export const GET_DATALIST_REQUEST = "GET_DATALIST_REQUEST";
export const GET_DATALIST_SUCCESS = "GET_DATALIST_SUCCESS";
export const GET_DATALIST_FAILURE = "GET_DATALIST_FAILURE";

//Get Data List
export const GET_DATALISTBYID_REQUEST = "GET_DATALISTBYID_REQUEST";
export const GET_DATALISTBYID_SUCCESS = "GET_DATALISTBYID_SUCCESS";
export const GET_DATALISTBYID_FAILURE = "GET_DATALISTBYID_FAILURE";

//Update data List
export const UPDATE_DATALIST_REQUEST = "UPDATE_DATALIST_REQUEST";
export const UPDATE_DATALIST_SUCCESS = "UPDATE_DATALIST_SUCCESS";
export const UPDATE_DATALIST_FAILURE = "UPDATE_DATALIST_FAILURE";

//Update Datalist status
export const UPDATE_DATALIST_STATUS_REQUEST = "UPDATE_DATALIST_REQUEST";
export const UPDATE_DATALIST_STATUS_SUCCESS = "UPDATE_DATALIST_STATUS_SUCCESS";
export const UPDATE_DATALIST_STATUS_FAILURE = "UPDATE_DATALIST_STATUS_FAILURE";

//Delete Datalist
export const REMOVE_DATALIST_REQUEST = "REMOVE_DATALIST_REQUEST";
export const REMOVE_DATALIST_SUCCESS = "REMOVE_DATALIST_SUCCESS";
export const REMOVE_DATALIST_FAIL = "REMOVE_DATALIST_FAIL";

//Get Datalist Tag Options
export const GET_ALL_DATALIST_TAGS_REQUEST = "GET_ALL_DATALIST_TAGS_REQUEST";
export const GET_ALL_DATALIST_TAGS_SUCCESS = "GET_ALL_DATALIST_TAGS_SUCCESS";
export const GET_ALL_DATALIST_TAGS_FAIL = "GET_ALL_DATALIST_TAGS_FAIL";

//Get Field Types
export const FIELD_TYPES_REQUEST = "FIELD_TYPES_REQUEST";
export const FIELD_TYPES_SUCCESS = "FIELD_TYPES_SUCCESS";
export const FIELD_TYPES_FAIL = "FIELD_TYPES_FAIL";

//Create Field Types
export const FIELD_CREATE_REQUEST = "FIELD_CREATE_REQUEST";
export const FIELD_CREATE_SUCCESS = "FIELD_CREATE_SUCCESS";
export const FIELD_CREATE_FAIL = "FIELD_CREATE_FAIL";

//Update Field Types
export const UPDATE_FIELD_REQUEST = "UPDATE_FIELD_REQUEST";
export const UPDATE_FIELD_SUCCESS = "UPDATE_FIELD_SUCCESS";
export const UPDATE_FIELD_FAILURE = "UPDATE_FIELD_FAILURE";

//Get Data Entry/
export const GET_ALL_DATA_REQUEST = "GET_ALL_DATA_REQUEST";
export const GET_ALL_DATA_SUCCESS = "GET_ALL_DATA_SUCCESS";
export const GET_ALL_DATA_FAIL = "GET_ALL_DATA_FAIL";

export const GET_DATA_ENTRY_REQUEST = "GET_DATA_ENTRY_REQUEST";
export const GET_DATA_ENTRY_SUCCESS = "GET_DATA_ENTRY_SUCCESS";
export const GET_DATA_ENTRY_FAIL = "GET_DATA_ENTRY_FAIL";

//Add New Data Entry
export const ADD_DATA_ENTRY_REQUEST = "ADD_DATA_ENTRY_REQUEST";
export const ADD_DATA_ENTRY_SUCCESS = "ADD_DATA_ENTRY_SUCCESS";
export const ADD_DATA_ENTRY_FAIL = "ADD_DATA_ENTRY_FAIL";

//Delete Data Entry
export const DELETE_DATA_ENTRY_REQUEST = "DELETE_DATA_ENTRY_REQUEST";
export const DELETE_DATA_ENTRY_SUCCESS = "DELETE_DATA_ENTRY_SUCCESS";
export const DELETE_DATA_ENTRY_FAIL = "DELETE_DATA_ENTRY_FAIL";

//Update Data Entry
export const UPDATE_DATA_ENTRY_REQUEST = "UPDATE_DATA_ENTRY_REQUEST";
export const UPDATE_DATA_ENTRY_SUCCESS = "UPDATE_DATA_ENTRY_SUCCESS";
export const UPDATE_DATA_ENTRY_FAIL = "UPDATE_DATA_ENTRY_FAIL";

//Get Data Field
export const GET_DATAFIELD_REQUEST = "GET_DATAFIELD_REQUEST";
export const GET_DATAFIELD_SUCCESS = "GET_DATAFIELD_SUCCESS";
export const GET_DATAFIELD_FAIL = "GET_DATAFIELD_FAIL";

//Delete Data Feild
export const DELETE_FIELD_REQUEST = "DELETE_FIELD_REQUEST";
export const DELETE_FIELD_SUCCESS = "DELETE_FIELD_SUCCESS";
export const DELETE_FIELD_FAILURE = "DELETE_FIELD_FAILURE";
