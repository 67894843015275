import {
    GET_ALL_DATA_SUCCESS,
    GET_ALL_DATA_REQUEST,
    GET_ALL_DATA_FAIL,
    GET_DATA_ENTRY_REQUEST,
    GET_DATA_ENTRY_SUCCESS,
    GET_DATA_ENTRY_FAIL,
    ADD_DATA_ENTRY_REQUEST,
    ADD_DATA_ENTRY_SUCCESS,
    ADD_DATA_ENTRY_FAIL,
    UPDATE_DATA_ENTRY_REQUEST,
    UPDATE_DATA_ENTRY_SUCCESS,
    UPDATE_DATA_ENTRY_FAIL,
  
  } from "../actions/types";

export const getAllDataReducer = (state = { data: [] }, action) => {
    switch (action.type) {
      case GET_ALL_DATA_REQUEST:
        return {
          loading: true,
          data: [],
        };
  
      case GET_ALL_DATA_SUCCESS:
        return {
          loading: false,
          data: action.payload,
        };
  
      case GET_ALL_DATA_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };

export const getDataEntriesReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_DATA_ENTRY_REQUEST:
      return {
        loading: true,
        data: [],
      };

    case GET_DATA_ENTRY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };

    case GET_DATA_ENTRY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const addDataEntryReducer = (state = { data: {} }, action) => {
  switch (action.type) {
    case ADD_DATA_ENTRY_REQUEST:
      return {
        loading: true,
        data: {},
      };

    case ADD_DATA_ENTRY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        success: true,
      };

    case ADD_DATA_ENTRY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const updateDataEntryReducer = (state = {data: {}}, action) => {
  switch (action.type) {
    case UPDATE_DATA_ENTRY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case UPDATE_DATA_ENTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        success: true,
      };

    case UPDATE_DATA_ENTRY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };

    default:
      return state;
  }
};

