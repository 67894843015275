import {
  CREATE_DATALIST_REQUEST,
  CREATE_DATALIST_SUCCESS,
  CREATE_DATALIST_FAILURE,
  GET_DATALIST_REQUEST,
  GET_DATALIST_SUCCESS,
  GET_DATALIST_FAILURE,
  GET_DATALISTBYID_REQUEST,
  GET_DATALISTBYID_SUCCESS,
  GET_DATALISTBYID_FAILURE,
  GET_ALL_DATALIST_TAGS_SUCCESS,
  GET_ALL_DATALIST_TAGS_REQUEST,
  GET_ALL_DATALIST_TAGS_FAIL,
  UPDATE_DATALIST_REQUEST,
  UPDATE_DATALIST_SUCCESS,
  UPDATE_DATALIST_FAILURE,
} from "../actions/types";

const initialState = {
  loading: false,
  success: false,
  error: null,
  datalist: [],
};

export const createDataListReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DATALIST_REQUEST:
      return { ...state, loading: true, success: false, error: null };
    case CREATE_DATALIST_SUCCESS:
      return { ...state, loading: false, success: true, data: action.payload };
    case CREATE_DATALIST_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getDataListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATALIST_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };

    case GET_DATALIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        datalist: action.payload,
        error: null,
      };

    case GET_DATALIST_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

//Get All Tags for Datalist
export const getDatalistByIdReducer = (
  state = { dataListItem: [] },
  action
) => {
  switch (action.type) {
    case GET_DATALISTBYID_REQUEST:
      return {
        loading: true,
        dataListItem: [],
      };

    case GET_DATALISTBYID_SUCCESS:
      return {
        loading: false,
        dataListItem: action.payload,
      };

    case GET_DATALISTBYID_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

//Get All Tags for Datalist
export const getAllDatalistTagsReducer = (
  state = { tagsOptions: [] },
  action
) => {
  switch (action.type) {
    case GET_ALL_DATALIST_TAGS_REQUEST:
      console.log(GET_ALL_DATALIST_TAGS_REQUEST);
      return {
        loading: true,
        tagsOptions: [],
      };

    case GET_ALL_DATALIST_TAGS_SUCCESS:
      return {
        loading: false,
        tagsOptions: action.payload,
      };

    case GET_ALL_DATALIST_TAGS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

//Update Datalist
export const updateDatalistReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DATALIST_REQUEST:
      return { ...state, loading: true, success: false, error: null };

    case UPDATE_DATALIST_SUCCESS:
      return { ...state, loading: false, success: true, error: null };

    case UPDATE_DATALIST_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
