import {
  FIELD_TYPES_REQUEST,
  FIELD_TYPES_SUCCESS,
  FIELD_TYPES_FAIL,
  FIELD_CREATE_REQUEST,
  FIELD_CREATE_SUCCESS,
  FIELD_CREATE_FAIL,
  UPDATE_FIELD_REQUEST,
  UPDATE_FIELD_SUCCESS,
  UPDATE_FIELD_FAILURE,
  GET_DATAFIELD_REQUEST,
  GET_DATAFIELD_SUCCESS,
  GET_DATAFIELD_FAIL,
} from "../actions/types";

const initialState = {
  fieldTypes: [],
  dataList: {},
  loading: false,
  error: null,
  deletedFieldId: null,
  success: false,
  dataField: null,
};

export const fieldReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIELD_TYPES_REQUEST:
      return { ...state, loading: true };
    case FIELD_TYPES_SUCCESS:
      return { ...state, loading: false, fieldTypes: action.payload };
    case FIELD_TYPES_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const fieldCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case FIELD_CREATE_REQUEST:
      return { loading: true };
    case FIELD_CREATE_SUCCESS:
      return { loading: false, success: true, field: action.payload };
    case FIELD_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const fieldUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FIELD_REQUEST:
      return { ...state, loading: true };

    case UPDATE_FIELD_SUCCESS:
      return {
        loading: false,
        success: true,
        field: action.payload,
        error: null,
      };

    case UPDATE_FIELD_FAILURE:
      return { loading: false, success: false, error: action.payload };

    default:
      return state;
  }
};

export const getDataFieldsReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_DATAFIELD_REQUEST:
      return {
        loading: true,
        data: [],
      };

    case GET_DATAFIELD_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };

    case GET_DATAFIELD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
